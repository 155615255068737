@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap);
/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap'); */

html, body {
  /* background-color: #e89061; */
  /* background-color: rgba(99, 70, 136); */
  background-color: #141515;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

code {
  font-family: 'Inter', sans-serif;}

.App {
  padding-top: 120px;
  padding-left: 45px;
  max-width: 800px;
  color:#eee; 
  margin:auto;
}

h1 {
  font-size: 36px;
  font-weight: 700;
}

.song {
  font-size: 18px;
  color: #eee;
}

.links {
  font-weight: 400;
  margin-top: 15px;
  display: flex;
  flex-direction: column;
}

.links div {
  margin-top: 5px;
}

.links a {
  font-size: 16px;
  color: #eee;
  text-decoration: none;
}

.links img {
  width: 20px;
  height: 20px;
}
