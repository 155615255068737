/* @import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap');

html, body {
  /* background-color: #e89061; */
  /* background-color: rgba(99, 70, 136); */
  background-color: #141515;
  margin: 0;
  font-family: 'Inter', sans-serif;
}

code {
  font-family: 'Inter', sans-serif;}
